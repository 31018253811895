<template>
  <v-app class="app">
    <Header :showDrawer="() => setDrawerVisible(true)"/>
    <Navbar :propDrawer="drawer" :setDrawerVisible="setDrawerVisible"/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Section>
      <v-row no-gutters>
        <v-col align="center">2021 © Tous droits réservés.</v-col>
      </v-row>
    </Section>
  </v-app>
</template>

<script>
import { Header, Navbar } from '@/components/Navigation'
import { Section } from '@/components/Section'

export default {
  name: 'App',
  components: {
    Header: Header, Section: Section, Navbar: Navbar
  },
  methods: {
    setDrawerVisible(visible) {
      this.drawer = visible
    }
  },
  data: () => ({
    drawer: false
  })
};
</script>

<style lang="scss">
@import "scssVariables";

body {
  .app {

    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: normal !important;

    .subtitle-1, .subtitle-2 {
      font-family: 'Source Sans Pro', sans-serif !important;
      &.subtitle-1 {
        font-weight: 600 !important;
      }
    }


    .text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6, .text-h7, .subtitle-1, .subtitle-2 {
      font-family: 'Montserrat', sans-serif !important;
      
      @include respond-mobile {
        &.text-h3 {
          font-size: 2.2rem !important;
        }
        &.text-h2 {
          font-size: 2.6rem !important;
        }
      }
    }  
  }
}

</style>