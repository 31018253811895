<template>
  <ViewContainer :id="contactLink.to" :gray="true">
    <Section :title="$t(MessageLabelKey.CONTACT_US)">
      <v-row justify="space-between">
        <v-col cols="12" lg="4">
          <v-row no-gutters align="center" justify="center">
            <v-icon class="mr-3">{{mdiPhone}}</v-icon> <span class="font-weight-medium">027 323 67 52</span>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4">
          <v-row no-gutters align="center" justify="center">
            <v-icon class="mr-3">{{mdiMapMarker}}</v-icon> <span class="font-weight-medium">Route de Chandoline 25b, 1950 Sion </span>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4">
          <v-row no-gutters align="center" justify="center">
            <v-icon class="mr-3">{{mdiEmail}}</v-icon> <span class="font-weight-medium">geoinformation@ig-group.ch</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="6" cols="12" class="mt-5">
          <v-form ref="form" method="post" action="https://newsletter.infomaniak.com/external/submit" class="inf-form" target="_blank" lazy-validation @submit.prevent>
            <input type="email" name="email" style="display:none" />
            <input type="hidden" name="key" value="eyJpdiI6InNyVnMyNVNQVVJMMGx5RDBJdzUrbllHYTYyK3c0Rk0zZWZNSUlDNmFTdWM9IiwidmFsdWUiOiJXNDdhNjVZYTFcL2E5bkdBSk5iWUIyWldvUTVoZmdXUmFjcWVOMGFZcVV1OD0iLCJtYWMiOiJkMTUyMDE0Njk0NGI3YWI3MjgyZGYwMDVjODJkZWQ2OGE1ZGYzM2FmNTliMzE0ZTdhNjMxOThjZDFjYmRlYTc5In0=">
            <input type="hidden" name="webform_id" value="14930">
            <div class="inf-main_2109366d68770c5528aa56705516f364 pa-6">
              <v-card-title><v-icon color="rgb(29, 103, 240)" x-large left>{{ mdiSend }}</v-icon>Newsletter</v-card-title>
              <v-card-subtitle>{{ $t(MessageLabelKey.NEWSLETTER_DONT_MISS) }}</v-card-subtitle>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="9" class="mt-0 pt-0">
                    <v-text-field required :rules="emailRules" :placeholder="$t(MessageLabelKey.WRITE_EMAIL)" v-model="email" name="inf[1]" data-inf-meta="1" :value="email"></v-text-field>
                  </v-col>
                  <v-col class="mt-0 pt-0">
                    <v-btn type="submit" cols="12" md="3" class="mt-0 pt-0" depressed block @click="submit">Valider</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
        </v-form>
        </v-col>
      </v-row>
    </Section>
  </ViewContainer>
</template>

<script>
import MessageLabelKey from '@/classes/MessageLabelKey'
import ViewContainer from '@/components/ViewContainer'
import { Section } from '@/components/Section'
import { mdiPhone, mdiEmail, mdiMapMarker, mdiSend } from '@mdi/js'
import { contactLink } from '@/constants/links'

export default {
  data() {
    return  {
      MessageLabelKey: MessageLabelKey,
      mdiPhone: mdiPhone,
      mdiEmail: mdiEmail,
      mdiSend,
      mdiMapMarker: mdiMapMarker,
      contactLink: contactLink,
      email: '',
      emailRules: [
        v => !!v || this.$t(MessageLabelKey.EMAIL_REQUIRED),
        v => /.+@.+\..+/.test(v) || this.$t(MessageLabelKey.INVALID_EMAIL),
      ]
    }
  },
  components: {
    Section: Section,
    ViewContainer: ViewContainer
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$refs.form.$el.submit()
      }
    }
  }
}
</script>

<style>
.inf-main_2109366d68770c5528aa56705516f364{ background-color:#FFFFFF; border-radius: 15px; }
</style>