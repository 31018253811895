<template>
  <v-col class="iconed-item" v-bind:class="{ 'before-appear': !inPlace }" cols="12" md="6">
    <v-card v-observe-visibility="visibilityChanged" elevation="1" height="100%">
      <v-card-text>
        <v-img max-width="2.5rem" class="mb-4" :src="iconSrc"/>
        <p class="text-h6 text--primary" v-if="!!title">{{title}}</p>
        <p>
          <slot/>
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ['iconSrc', 'title'],
  data() {
    return {
      inPlace: false
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.inPlace) {
        this.inPlace = true
      }
    }
  }
}
</script>

<style lang="scss">
@import "scssVariables";

.iconed-item {
  overflow: hidden;
  transition: .2s all ease-in-out;
  transition-delay: .2s;
  -webkit-transition: .2s all ease-in-out;
  -webkit-transition-delay: .2s;
  &.before-appear {
    opacity: 0;
    @include respond-tablet {
    transform: translateX(80px);
    -webkit-transform: translateX(80px);
    }
    @include respond-desktop {
    transform: translateX(80px);
    -webkit-transform: translateX(80px);
    }
  }
}

</style>