<template>
<ViewContainer :id="solutionsLink.to" :gray="true">
  <v-row>
    <v-col class=12>
    <Section :title="$t(MessageLabelKey.SOLUTIONS_VIEW_TITLE)">
      <Subsection title="Aquaspy Manager" :subtitle="$t(MessageLabelKey.AQUASPY_MANAGER_SUBTITLE)">
        <template>
          <v-col>
            <v-row align="center">
              <v-col lg="6" md="5">
                <v-row align="center" justify="center" no-gutters>
                  <v-img max-width="400px" :src="require('@/assets/gestion.svg')"/>
                </v-row>
              </v-col>
              <v-col lg="6" md="7" cols="12">
                <v-row>
                <IconedItem :iconSrc="require('@/assets/eye.svg')" :title="$t(MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TITLE)">
                  {{$t(MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TEXT)}}
                </IconedItem>
                <IconedItem :iconSrc="require('@/assets/alert.svg')" :title="$t(MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TITLE)">
                  {{$t(MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TEXT)}}
                </IconedItem>
                <IconedItem :iconSrc="require('@/assets/sample.svg')" :title="$t(MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TITLE)">
                  {{$t(MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TEXT)}}
                </IconedItem>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </Subsection>
      <v-row class="my-4">
        <v-divider/>
      </v-row>
      <Subsection title="Aquaspy Public" :subtitle="$t(MessageLabelKey.AQUASPY_PUBLIC_SUBTITLE)">
        <template>
          <v-col>
            <v-row align="center">
              <v-col lg="6" md="5">
                <v-row align="center" justify="center" no-gutters>
                <v-img max-width="250px" :src="require('@/assets/aquaspy_public.svg')"/>
                </v-row>
              </v-col>
              <v-col lg="6" md="7" cols="12">
                <v-row>
                <IconedItem :iconSrc="require('@/assets/check.svg')" :title="$t(MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TITLE)">
                  {{$t(MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TEXT)}}
                </IconedItem>
                <IconedItem :iconSrc="require('@/assets/glass.svg')" :title="$t(MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TITLE)">
                  {{$t(MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TEXT)}}
                </IconedItem>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </Subsection>
    </Section>
    </v-col>
  </v-row>
</ViewContainer>
</template>

<script>
import { Section, Subsection } from '@/components/Section'
import ViewContainer from '@/components/ViewContainer'
import IconedItem from '@/components/IconedItem'
import MessageLabelKey from '@/classes/MessageLabelKey'
import { solutionsLink } from '@/constants/links'

export default {
  data () {
    return {
      solutionsLink: solutionsLink,
      MessageLabelKey: MessageLabelKey
    }
  },
  components: { Section: Section, Subsection: Subsection, IconedItem: IconedItem, ViewContainer: ViewContainer }
}
</script>

<style>

</style>