import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)
Vue.use(Vuetify);

export default new Vuetify({

});
