<template>
  <v-container fluid class="view-container" v-bind:class="{ 'gray': gray }">
    <slot/>
  </v-container>
</template>

<script>
import { ScreenMixins } from '@/mixins'

export default {
  props: ['gray'],
  mixins: [ScreenMixins]
}
</script>

<style lang="scss" scoped>
@import "scssVariables";

.view-container {
  &.gray {
    background-color: rgb(245, 249, 250);
  }
  @include respond-mobile {
    padding: 20px calc((100% - #{$main-horizontal-width-mobile}) / 2) ;
  }
  @include respond-tablet {
    padding: 40px calc((100% - #{$main-horizontal-width-tablet}) / 2) ;
  }
  @include respond-desktop {
    padding: 60px calc((100% - #{$main-horizontal-width-desktop}) / 2) ;
  }
}
</style>
