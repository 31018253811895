import MessageLabelKey from '../classes/MessageLabelKey'

const firstViewExplanation1_fr = "Aquaspy estime et diffuse en temps réel les paramètres physiques et chimiques en tout point du réseau d'eau potable."
const firstViewExplanation1_en = "Aquaspy estimates and broadcasts in real time the physical and chemical parameters at any point in the drinking water network."
const firstViewExplanation1_de = "Aquaspy schätzt und sendet in Echtzeit die physikalischen und chemischen Parameter an jedem Punkt des Trinkwassernetzes."
const firstViewExplanation1_it = "Aquaspy stima e trasmette in tempo reale i parametri fisici e chimici in qualsiasi punto della rete dell'acqua potabile."

export default {
  fr: {
    // First view
    [MessageLabelKey.FIRST_VIEW_CATCH_PHRASE]: "Les données de l'eau potable en toute transparence.",
    [MessageLabelKey.FIRST_VIEW_EXPLANATION_1]: firstViewExplanation1_fr,

    // Links
    [MessageLabelKey.FUNCTIONING]: 'Fonctionnement',
    [MessageLabelKey.SOLUTIONS]: 'Solutions',
    [MessageLabelKey.WHO]: 'Qui sommes-nous ?',
    [MessageLabelKey.CONTACT_US]: 'Contactez-nous',

    // Solutions view
    [MessageLabelKey.SOLUTIONS_VIEW_TITLE]: 'Nos solutions',
    [MessageLabelKey.AQUASPY_MANAGER_SUBTITLE]: 'Un portail cartographique dédié aux distributeurs et à leurs collaborateurs',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TITLE]: 'Visualiser',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TEXT]: "Visualiser sur une carte l'évolution des valeurs physico-chimiques en tout point du réseau",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TITLE]: "Gérer",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TEXT]: "Gérer des alertes déclenchées selon des seuils fixés par les utilisateurs",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TITLE]: "Intégrer",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TEXT]: "Intégrer des mesures de qualité dans un modèle numérique",
    [MessageLabelKey.AQUASPY_PUBLIC_SUBTITLE]: "Valoriser la qualité de l'eau potable du réseau auprès des consommateurs",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TITLE]: "Consulter",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TEXT]: "Consulter des indicateurs de qualité à une adresse",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TITLE]: "S'informer",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TEXT]: "Être informé de l'état du réseau et des mesures à prendre en cas d'incident",

    // Functioning View
    [MessageLabelKey.FUNCTIONING_STEP_1_TITLE]: "Réseau d'eau monitoré",
    [MessageLabelKey.FUNCTIONING_STEP_1_TEXT]: "Le réseau d'eau réel est monitoré par des mesures de paramètres physiques (débit, pression) et des mesures de qualité (température, pH, turbidité, composants chimiques). Les mesures de qualité sont faites manuellement ou sont automatisées par des capteurs spécifique.",
    [MessageLabelKey.FUNCTIONING_STEP_2_TITLE]: "Simulation numérique",
    [MessageLabelKey.FUNCTIONING_STEP_2_TEXT]: "Les tracés, diamètres et matériaux des conduites ainsi que les autres objets qui constituent le réseau (vannes, pompes etc.) sont exploités pour créer un modèle hydraulique numérique. Les capteurs et les mesures y sont intégrés. Pour chaque période de mesure, une nouvelle simulation hydraulique et chimique est réalisée. Les valeurs calculées sont enregistrées sur la plateforme web. Si les valeurs mesurées et calculées dépassent certains seuils, des alertes sont lancées.",
    [MessageLabelKey.FUNCTIONING_STEP_3_TITLE]: "Plateformes de consultation",
    [MessageLabelKey.FUNCTIONING_STEP_3_TEXT]: "Le distributeur et ses collaborateurs peuvent consulter la cartographie du réseau ainsi que les valeurs simulées et mesurées sur une plateforme web. La plateforme permet en outre de gérer les alertes et de consulter l'historique des paramètres. Les valeurs peuvent aussi être accédées par une API, si elles doivent être intégrées à un autre support. \nLe distributeur peut décider de mettre à disposition des consommateurs une plateforme publique. Elle permet au consommateur de consulter la qualité de l'eau sur un point du réseau (par exemple une adresse), de consulter l'état du réseau et d'être informé des mesures à prendre en cas d'incident.",

    // Staff
    [MessageLabelKey.STAFF_PARTNERS]: "Partenaires",
    [MessageLabelKey.STAFF_IG]: "IG Group est un bureau d'ingénieurs et de géomètres valaisan. Il possède une équipe spécialisée en géoinformatique qui développe notamment des portails cartographiques. Cette équipe réalise le développement informatique et la commercialisation d'Aquaspy.",
    [MessageLabelKey.STAFF_HISTORY]: "Historique",
    [MessageLabelKey.STAFF_ALTIS]: "Société actrice de la transition énergétique et de l’innovation dans les domaines de l’eau et de l’énergie dans le val de Bagne. Altis est le premier client d'Aquaspy et exprime les besoins des distributeurs d'eau. La société apporte aussi ses compétences en hydraulique au projet.",
    [MessageLabelKey.STAFF_BLUEARK]: "Le pôle d'innovation est spécialisé dans la gestion intelligente de l’eau et de manière plus large, de la protection de l’environnement. Il organise le <a href='https://www.blueark-challenge.ch/'>blueArk challenge</a>, un hackathon à ses premières heures qui s'est mué en appel à projet en 2020. Aquaspy est issu du projet vainqueur de l'édition 2019.",
    [MessageLabelKey.STAFF_HISTORY_TEXT]: "Aquaspy a été initié lors du hackathon du BlueArk Challenge en 2019. L'équipe qui a remporté ce challenge était constituée de deux géoinformaticiens de IG group et d'un <a href='https://www.sh-ing.ch/'>hydraulicien indépendant</a>. La solution avait su convaincre le jury formé notamment de Peter Brabeck, ancien directeur de Nestlé.<br>IG group a décidé de poursuivre ce projet. Un premier prototype pour le réseau de Lourtier géré par Altis a été finalisé début 2021, le développement a été soutenu par la fondation The Ark et Innosuisse (chèque innovation en partenariat avec <a href='https://heig-vd.ch/rad/instituts/mei'>l'institut MEI</a>). Pour réaliser ce prototype les compétences hydrauliques ont été amenées par Altis, les compétences (géo-)informatiques par MEI et IG group.<br>Une version finalisée d'Aquaspy est cours de travail et sera disponible fin 2021, début 2022.",

    // Components
    [MessageLabelKey.SEE_MORE]: "Voir plus",

    // Media
    [MessageLabelKey.MEDIA_SECTION_TITLE]: "Médias",

    // Newsletter
    [MessageLabelKey.NEWSLETTER_DONT_MISS]: "Ne manquez pas les nouveautés d'Aquaspy.",
    [MessageLabelKey.WRITE_EMAIL]: "Renseignez votre adresse e-mail.",
    [MessageLabelKey.EMAIL_REQUIRED]: "Une adresse e-mail doit être précisée.",
    [MessageLabelKey.INVALID_EMAIL]: "L'adresse e-mail renseignée est invalide."
  },
  en: {
    // First view
    [MessageLabelKey.FIRST_VIEW_CATCH_PHRASE]: "Drinking water data in full transparency.",
    [MessageLabelKey.FIRST_VIEW_EXPLANATION_1]: firstViewExplanation1_en,

    // Links
    [MessageLabelKey.FUNCTIONING]: 'Functioning',
    [MessageLabelKey.SOLUTIONS]: 'Products',
    [MessageLabelKey.WHO]: 'Who are we ?',
    [MessageLabelKey.CONTACT_US]: 'Contact us',

    // Solutions view
    [MessageLabelKey.SOLUTIONS_VIEW_TITLE]: 'Our products',
    [MessageLabelKey.AQUASPY_MANAGER_SUBTITLE]: 'A mapping portal dedicated to distributors and their partners',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TITLE]: 'Visualise',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TEXT]: "Visualise on a map the evolution of physico-chemical values at any point of the network",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TITLE]: "Manage",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TEXT]: "Manage alerts triggered by user-defined thresholds",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TITLE]: "Integrate",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TEXT]: "Integrate quality measurements into the numerical model",
    [MessageLabelKey.AQUASPY_PUBLIC_SUBTITLE]: "Promote the quality of the drinking water network to consumers",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TITLE]: "Check",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TEXT]: "Consult quality indicators at an address",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TITLE]: "Get informed",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TEXT]: "Be informed about the state of the network and the measures to be taken in case of an incident",

    // Functioning View
    [MessageLabelKey.FUNCTIONING_STEP_1_TITLE]: "Monitored water network",
    [MessageLabelKey.FUNCTIONING_STEP_1_TEXT]: "The real water network is monitored by physical parameters (flow, pressure) and quality measurements (temperature, pH, turbidity, chemical components). The quality measurements are made manually or are automated by specific sensors.",
    [MessageLabelKey.FUNCTIONING_STEP_2_TITLE]: "Numerical simulation",
    [MessageLabelKey.FUNCTIONING_STEP_2_TEXT]: "The routes, diameters and materials of the pipes as well as the other objects that make up the network (valves, pumps etc.) are used to create a digital hydraulic model. Sensors and measurements are integrated into the model. For each measurement period, a new hydraulic and chemical simulation is performed. The calculated values are stored on the web platform. If the measured and calculated values exceed certain thresholds, alerts are issued.",
    [MessageLabelKey.FUNCTIONING_STEP_3_TITLE]: "Consultation platforms",
    [MessageLabelKey.FUNCTIONING_STEP_3_TEXT]: "The distributor and its partners can consult the network map as well as the simulated and measured values on a web platform. The platform also allows for the management of alerts and the consultation of historical parameters. The values can also be accessed via an API, if they are to be integrated into another medium. \nThe distributor may decide to make a public platform available to consumers. This allows the consumer to view the water quality at a point in the network (e.g. an address), to view the status of the network, and to be informed of actions to be taken in the event of an incident.",

    // Staff
    [MessageLabelKey.STAFF_PARTNERS]: "Partners",
    [MessageLabelKey.STAFF_HISTORY]: "History",
    [MessageLabelKey.STAFF_ALTIS]: "Company involved in the energy transition and innovation in the fields of water and energy in the Val de Bagne. Altis is Aquaspy's first client and expresses the needs of water distributors. The company also brings its hydraulic skills to the project.",
    [MessageLabelKey.STAFF_IG]: "IG Group is an engineering and surveying office in the Valais. It has a team specialising in geoinformatics, which develops cartographic portals. This team is responsible for the development and marketing of Aquaspy.",
    [MessageLabelKey.STAFF_BLUEARK]: "The innovation cluster specialises in intelligent water management and, more broadly, environmental protection. It organises the <a href='https://www.blueark-challenge.ch/'>blueArk challenge</a>, a hackathon in its early days that turned into a call for projects in 2020. Aquaspy is from the winning project of the 2019 edition.",
    [MessageLabelKey.STAFF_HISTORY_TEXT]: "Aquaspy was initiated at the BlueArk Challenge hackathon in 2019. The team that won the challenge consisted of two geoinformaticians from IG group and an <a href='https://www.sh-ing.ch/'>independent hydraulic engineer</a>. The solution convinced the jury, which included Peter Brabeck, former director of Nestlé.<br>IG group decided to continue the project. A first prototype for the Lourtier network managed by Altis was finalised at the beginning of 2021, the development was supported by The Ark foundation and Innosuisse (innovation cheque in partnership with <a href='https://heig-vd.ch/rad/instituts/mei'>the MEI Institute</a>). To realise this prototype the hydraulic skills were brought by Altis, the (geo-)IT skills by MEI and IG group.<br>A finalised version of Aquaspy is being worked on and will be available end of 2021, early 2022.",

    // Components
    [MessageLabelKey.SEE_MORE]: "See more",

    // Media
    [MessageLabelKey.MEDIA_SECTION_TITLE]: "Media",

    // Newsletter
    [MessageLabelKey.NEWSLETTER_DONT_MISS]: "Don't miss the latest from Aquaspy.",
    [MessageLabelKey.WRITE_EMAIL]: "Enter your e-mail address.",
    [MessageLabelKey.EMAIL_REQUIRED]: "An e-mail address must be specified.",
    [MessageLabelKey.INVALID_EMAIL]: "The e-mail address entered is invalid."
  },
  de: {
    // First view
    [MessageLabelKey.FIRST_VIEW_CATCH_PHRASE]: "Trinkwasserdaten in voller Transparenz.",
    [MessageLabelKey.FIRST_VIEW_EXPLANATION_1]: firstViewExplanation1_de,

    // Links
    [MessageLabelKey.FUNCTIONING]: 'Betrieb',
    [MessageLabelKey.SOLUTIONS]: 'Produkte',
    [MessageLabelKey.WHO]: 'Wer sind wir ?',
    [MessageLabelKey.CONTACT_US]: 'Kontakt',

    // Solutions view
    [MessageLabelKey.SOLUTIONS_VIEW_TITLE]: 'Unsere Produkte',
    [MessageLabelKey.AQUASPY_MANAGER_SUBTITLE]: 'Ein Mapping-Portal speziell für Distributoren und ihre Partner',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TITLE]: 'Ansehen',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TEXT]: "Zur Visualisierung der Entwicklung der physikalisch-chemischen Werte in einem beliebigen Punkt des Netzwerks auf einer Karte",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TITLE]: "Verwalten",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TEXT]: "Verwalten von Alarmen, die durch benutzerdefinierte Schwellenwerte ausgelöst werden",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TITLE]: "Integrate",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TEXT]: "Integrieren Sie Qualitätsmessungen in das numerische Modell",
    [MessageLabelKey.AQUASPY_PUBLIC_SUBTITLE]: "Förderung der Qualität des Trinkwassernetzes bei den Verbrauchern",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TITLE]: "Anzeigen",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TEXT]: "Anzeigen von Qualitätsindikatoren unter einer Adresse",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TITLE]: "Sich informieren",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TEXT]: "Über den Zustand des Netzwerks und die Maßnahmen im Falle eines Vorfalls informiert werden",

    // Functioning View
    [MessageLabelKey.FUNCTIONING_STEP_1_TITLE]: "Überwachtes Wassernetz",
    [MessageLabelKey.FUNCTIONING_STEP_1_TEXT]: "Das reale Wassernetz wird durch physikalische Parameter (Durchfluss, Druck) und Qualitätsmessungen (Temperatur, pH-Wert, Trübung, chemische Komponenten) überwacht. Die Qualitätsmessungen erfolgen manuell oder werden durch spezielle Sensoren automatisiert.",
    [MessageLabelKey.FUNCTIONING_STEP_2_TITLE]: "Digitale Simulation",
    [MessageLabelKey.FUNCTIONING_STEP_2_TEXT]: "Mit den Trassen, Durchmessern und Materialien der Rohre sowie den anderen Objekten, aus denen das Netz besteht (Ventile, Pumpen usw.), wird ein digitales Hydraulikmodell erstellt. Sensoren und Messungen werden in das Modell integriert. Für jede Messperiode wird eine neue hydraulische und chemische Simulation durchgeführt. Die berechneten Werte werden auf der Webplattform gespeichert. Wenn die gemessenen und berechneten Werte bestimmte Schwellenwerte überschreiten, werden Alarme ausgegeben.",
    [MessageLabelKey.FUNCTIONING_STEP_3_TITLE]: "Konsultationsplattformen",
    [MessageLabelKey.FUNCTIONING_STEP_3_TEXT]: "Der Verteiler und seine Partner können den Netzplan sowie die simulierten und gemessenen Werte auf einer Web-Plattform einsehen. Die Plattform ermöglicht auch die Verwaltung von Alarmen und die Abfrage der Historie der Parameter. Die Werte können auch über eine API abgerufen werden, wenn sie in ein anderes Medium integriert werden sollen.\nDer Händler kann sich entscheiden, eine öffentliche Plattform für Verbraucher zur Verfügung zu stellen. Sie ermöglicht es dem Verbraucher, die Wasserqualität an einem Punkt des Netzes (z. B. einer Adresse) einzusehen, den Status des Netzes abzurufen und über Maßnahmen im Störungsfall informiert zu werden.",

    // Staff
    [MessageLabelKey.STAFF_PARTNERS]: "Partner",
    [MessageLabelKey.STAFF_HISTORY]: "Geschichte",
    [MessageLabelKey.STAFF_IG]: "IG Group ist ein Walliser Ingenieur- und Vermessungsbüro. Es hat ein auf Geoinformatik spezialisiertes Team, das kartographische Portale entwickelt. Dieses Team ist für die Entwicklung und Vermarktung von Aquaspy verantwortlich.",
    [MessageLabelKey.STAFF_ALTIS]: "Unternehmen, das sich mit der Energiewende und Innovationen in den Bereichen Wasser und Energie im Val de Bagne beschäftigt. Altis ist der erste Kunde von Aquaspy und bringt die Bedürfnisse von Wasserverteilern zum Ausdruck. Das Unternehmen bringt auch seine hydraulischen Fähigkeiten in das Projekt ein.",
    [MessageLabelKey.STAFF_BLUEARK]: "Der Innovationscluster ist auf intelligentes Wassermanagement und im weiteren Sinne auf den Umweltschutz spezialisiert. Sie organisiert die <a href='https://www.blueark-challenge.ch/'>blueArk challenge</a>, einen Hackathon der ersten Stunde, der 2020 in einen Projektaufruf überging. Aquaspy ist aus dem Siegerprojekt der Ausgabe 2019.",
    [MessageLabelKey.STAFF_HISTORY_TEXT]: "Aquaspy wurde beim BlueArk Challenge-Hackathon 2019 initiiert. Das Team, das die Herausforderung gewann, bestand aus zwei Geoinformatikern der IG-Gruppe und einem <a href='https://www.sh-ing.ch/'>unabhängigen Wasserbauingenieur</a>. Die Lösung hatte die Jury, zu der auch der ehemalige Nestlé-Chef Peter Brabeck gehörte, überzeugt.<br>Der Konzern entschied sich, das Projekt weiterzuführen. Ein erster Prototyp für das von Altis verwaltete Lourtier-Netzwerk wurde Anfang 2021 fertiggestellt, die Entwicklung wurde von der Stiftung The Ark und Innosuisse (Innovationscheck in Partnerschaft mit <a href='https://heig-vd.ch/rad/instituts/mei'>dem MEI-Institut</a>) unterstützt. Um diesen Prototyp zu realisieren, wurden die hydraulischen Fähigkeiten von Altis eingebracht, die (Geo-)Rechenfähigkeiten von MEI und IG group.<br>Eine finalisierte Version von Aquaspy ist in Arbeit und wird Ende 2021, Anfang 2022 verfügbar sein.",

    // Components
    [MessageLabelKey.SEE_MORE]: "Mehr sehen",

    // Media
    [MessageLabelKey.MEDIA_SECTION_TITLE]: "Medien",

    // Newsletter
    [MessageLabelKey.NEWSLETTER_DONT_MISS]: "Verpassen Sie nicht die Neuheiten von Aquaspy.",
    [MessageLabelKey.WRITE_EMAIL]: "Geben Sie Ihre E-Mail-Adresse ein.",
    [MessageLabelKey.EMAIL_REQUIRED]: "Es muss eine E-Mail-Adresse angegeben werden.",
    [MessageLabelKey.INVALID_EMAIL]: "Die angegebene E-Mail-Adresse ist ungültig."
  },
  it: {
    // First view
    [MessageLabelKey.FIRST_VIEW_CATCH_PHRASE]: "Dati sull'acqua potabile in piena trasparenza.",
    [MessageLabelKey.FIRST_VIEW_EXPLANATION_1]: firstViewExplanation1_it,

    // Links
    [MessageLabelKey.FUNCTIONING]: 'Funzionamento',
    [MessageLabelKey.SOLUTIONS]: 'Prodotti',
    [MessageLabelKey.WHO]: 'Chi siamo?',
    [MessageLabelKey.CONTACT_US]: 'Contattateci',

    // Solutions view
    [MessageLabelKey.SOLUTIONS_VIEW_TITLE]: 'I prodotti',
    [MessageLabelKey.AQUASPY_MANAGER_SUBTITLE]: 'Un portale di mappatura dedicato ai distributori e ai loro partner',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TITLE]: 'Visualizzare',
    [MessageLabelKey.SOLUTIONS_MANAGER_VISUALIZE_TEXT]: "Visualizzare su una mappa l'evoluzione dei valori fisico-chimici in qualsiasi punto della rete",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TITLE]: "Gestire",
    [MessageLabelKey.SOLUTIONS_MANAGER_ALERT_TEXT]: "Gestire gli avvisi attivati da soglie definite dall'utente",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TITLE]: "Integrate",
    [MessageLabelKey.SOLUTIONS_MANAGER_SAMPLE_TEXT]: "Integrare le misurazioni della qualità nel modello numerico",
    [MessageLabelKey.AQUASPY_PUBLIC_SUBTITLE]: "Promuovere la qualità della rete di acqua potabile presso i consumatori",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TITLE]: "Visitare",
    [MessageLabelKey.SOLUTIONS_PUBLIC_CHECK_TEXT]: "Visualizza gli indicatori di qualità ad un indirizzo",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TITLE]: "Scoprire",
    [MessageLabelKey.SOLUTIONS_PUBLIC_INFORMED_TEXT]: "Essere informati sullo stato della rete e sulle misure da prendere in caso di incidente",

    // Functioning View
    [MessageLabelKey.FUNCTIONING_STEP_1_TITLE]: "Rete idrica monitorata",
    [MessageLabelKey.FUNCTIONING_STEP_1_TEXT]: "La rete idrica reale è monitorata da parametri fisici (flusso, pressione) e misure di qualità (temperatura, pH, torbidità, componenti chimici). Le misure di qualità sono fatte manualmente o sono automatizzate da sensori specifici.",
    [MessageLabelKey.FUNCTIONING_STEP_2_TITLE]: "Simulazione digitale",
    [MessageLabelKey.FUNCTIONING_STEP_2_TEXT]: "I percorsi, i diametri e i materiali dei tubi e gli altri oggetti che compongono la rete (valvole, pompe, ecc.) sono utilizzati per creare un modello idraulico digitale. I sensori e le misurazioni sono integrati nel modello. Per ogni periodo di misurazione, viene eseguita una nuova simulazione idraulica e chimica. I valori calcolati sono memorizzati sulla piattaforma web. Se i valori misurati e calcolati superano determinate soglie, vengono emessi degli allarmi.",
    [MessageLabelKey.FUNCTIONING_STEP_3_TITLE]: "Piattaforme di consultazione",
    [MessageLabelKey.FUNCTIONING_STEP_3_TEXT]: "Il distributore e i suoi partner possono consultare la mappa della rete e i valori simulati e misurati su una piattaforma web. La piattaforma permette anche la gestione degli allarmi e la consultazione dello storico dei parametri. I valori possono anche essere accessibili tramite un'API, se devono essere integrati in un altro mezzo. \nIl distributore può decidere di mettere una piattaforma pubblica a disposizione dei consumatori. Permette al consumatore di visualizzare la qualità dell'acqua in un punto della rete (per esempio, un indirizzo), di vedere lo stato della rete e di essere informato sulle azioni da intraprendere in caso di incidente.",

    // Staff
    [MessageLabelKey.STAFF_PARTNERS]: "Partner",
    [MessageLabelKey.STAFF_HISTORY]: "Storia",
    [MessageLabelKey.STAFF_IG]: "IG Group è uno studio di ingegneria e di misurazione nel Vallese. Ha un team specializzato in geoinformatica che sviluppa portali cartografici. Questo team è responsabile dello sviluppo e del marketing di Aquaspy.",
    [MessageLabelKey.STAFF_ALTIS]: "Azienda impegnata nella transizione energetica e nell'innovazione nei settori dell'acqua e dell'energia nella Val de Bagne. Altis è il primo cliente di Aquaspy ed esprime le esigenze dei distributori d'acqua. L'azienda porta anche le sue competenze idrauliche al progetto.",
    [MessageLabelKey.STAFF_BLUEARK]: "Il cluster di innovazione è specializzato nella gestione intelligente dell'acqua e, più in generale, nella protezione dell'ambiente. Organizza il <a href='https://www.blueark-challenge.ch/'>blueArk challenge</a>, un hackathon agli inizi che si è trasformato in un bando per progetti nel 2020. Aquaspy è del progetto vincitore dell'edizione 2019.",
    [MessageLabelKey.STAFF_HISTORY_TEXT]: "Aquaspy è stato avviato all'hackathon BlueArk Challenge 2019. La squadra che ha vinto la sfida era composta da due geoinformatici del gruppo IG e un <a href='https://www.sh-ing.ch/'>ingegnere idraulico indipendente</a>. La soluzione aveva convinto la giuria, che comprendeva Peter Brabeck, ex capo della Nestlé.<br>Il gruppo SIG ha deciso di continuare il progetto. Un primo prototipo per la rete Lourtier gestito da Altis è stato finalizzato all'inizio del 2021, lo sviluppo è stato sostenuto da The Ark foundation e Innosuisse (controllo dell'innovazione in collaborazione con <a href='https://heig-vd.ch/rad/instituts/mei'>l'istituto MEI</a>). Per realizzare questo prototipo le competenze idrauliche sono state portate da Altis, le competenze (geo-)informatiche da MEI e IG group.<br>Una versione finalizzata di Aquaspy è in lavorazione e sarà disponibile alla fine del 2021, inizio 2022.",

    // Components
    [MessageLabelKey.SEE_MORE]: "Vedere più",

    // Media
    [MessageLabelKey.MEDIA_SECTION_TITLE]: "Medien",

    // Newsletter
    [MessageLabelKey.NEWSLETTER_DONT_MISS]: "Non perdetevi le ultime novità di Aquaspy.",
    [MessageLabelKey.WRITE_EMAIL]: "Inserite il vostro indirizzo e-mail.",
    [MessageLabelKey.EMAIL_REQUIRED]: "È necessario fornire un indirizzo e-mail.",
    [MessageLabelKey.INVALID_EMAIL]: "L'indirizzo e-mail inserito non è valido."
  }
}