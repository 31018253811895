import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Functioning from '../views/Functioning.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/functioning',
    name: 'Functioning',
    component: Functioning
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: Functioning
  },
  {
    path: '/who',
    name: 'Who',
    component: Functioning
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
