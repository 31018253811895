<template>
  <ViewContainer class="first-view" :id="firstViewLink.to">
    <v-row align="center" justify="center">
      <v-col lg="6" md="7" sm="10" xs="12">
        <v-row no-gutters align="center">
          <h1 class="text-h2 h2 text-md-left text-center text-xs-center">
          {{$t(MessageLabelKey.FIRST_VIEW_CATCH_PHRASE)}}
          </h1>
        </v-row>
        <v-row no-gutters>
          <h2 class="subtitle-1 mt-4 text-md-left text-sm-center text-center">
            {{$t(MessageLabelKey.FIRST_VIEW_EXPLANATION_1)}}
          </h2>
        </v-row>
      </v-col>
      <v-col lg="6" md="5" cols="12">
        <v-row no-gutters justify="center">
          <iframe :src="`https://player.vimeo.com/video/585727561?autoplay=1&loop=1&background=1`" height="500px" width="600px" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </v-row>
      </v-col>
    </v-row>
  </ViewContainer>
</template>

<script>
import MessageLabelKey from '@/classes/MessageLabelKey'
import { firstViewLink } from '@/constants/links'
import ViewContainer from '@/components/ViewContainer'
import screenMixins from '@/mixins/ScreenMixins'

export default {
  mixins: [screenMixins],
  data() {
    return {
      firstViewLink: firstViewLink,
      MessageLabelKey: MessageLabelKey
    }
  },
  computed: {
    imgWidth() {
      if (this.largeScreenMode) {
        return "650px"
      } else if (this.smallScreenMode) {
        return "350px"
      } else if (this.mediumScreenMode) {
        return "400px"
      }
      return "100%"
    }
  },
  components: {
    ViewContainer
  }
}
</script>

<style lang='scss'>
@import "scssVariables";

.first-view {
  @include respond-mobile {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  @include respond-tablet {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  @include respond-desktop {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}

</style>