<template>
  <ViewContainer :id="whoLink.to" :gray="true">
    <v-row no-gutters>
      <Section :title="$t(MessageLabelKey.WHO)">
        <Subsection>
          <v-col>
            <v-row justify="center">
              <v-col cols="12" md="5">
                <StaffMember :imgSrc="require('@/assets/ig_group.svg')" link="https://www.ig-group.ch" :imgWidth="320">
                  {{$t(MessageLabelKey.STAFF_IG)}}
                </StaffMember>
              </v-col>
            </v-row>
          </v-col>
        </Subsection>
        <Subsection :title="$t(MessageLabelKey.STAFF_PARTNERS)">
          <v-col>
            <v-row justify="center">
          <v-col cols="12" md="5">
            <v-row>
              <v-col>
            <StaffMember :imgSrc="require('@/assets/blue_ark.svg')" link="https://www.blueark.ch" :imgWidth="280">
              <span v-html="$t(MessageLabelKey.STAFF_BLUEARK)"/>
            </StaffMember>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
          <StaffMember :imgSrc="require('@/assets/altis.svg')" link="https://www.altis.swiss" :imgWidth="90">
            {{$t(MessageLabelKey.STAFF_ALTIS)}}
          </StaffMember>
          </v-col>
            </v-row>
          </v-col>
        </Subsection>
        <Subsection :title="$t(MessageLabelKey.STAFF_HISTORY)">
          <v-col>
            <v-row justify="space-between">
              <v-col md="5" cols="12">
                <v-img max-height="700" contain :src="require('@/assets/hydrovision.png')"/>
              </v-col>
              <v-col md="7" cols="12">
                <p style="white-space: pre-line;" class="text-justify text--secondary" v-html="$t(MessageLabelKey.STAFF_HISTORY_TEXT)">
                </p>
              </v-col>
            </v-row>
          </v-col>
        </Subsection>
      </Section>
    </v-row>
  </ViewContainer>
</template>

<script>
import ViewContainer from '@/components/ViewContainer'
import { Section, Subsection } from '@/components/Section'
import MessageLabelKey from '@/classes/MessageLabelKey'
import StaffMember from '@/components/Staff/StaffMember'
import { whoLink } from '@/constants/links'

export default {
  data() {
    return  {
      whoLink: whoLink,
      MessageLabelKey: MessageLabelKey
    }
  },
  components: { ViewContainer: ViewContainer, Section: Section, Subsection: Subsection, StaffMember: StaffMember }
}
</script>

<style>

</style>