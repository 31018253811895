<template>
  <ViewContainer :id="mediaLink.to">
    <v-row no-gutters>
      <Section :title="$t(MessageLabelKey.MEDIA_SECTION_TITLE)">
        <v-row>
          <v-col v-for="(ref, index) in references" :key="index" xl="3" md="4" sm="12">
            <Link :img="ref.img" :text="ref.text" :date="ref.date" :url="ref.url" :dark="ref.dark" />
          </v-col>
        </v-row>
      </Section>
    </v-row>
  </ViewContainer>
</template>

<script>
import ViewContainer from '@/components/ViewContainer'
import { Section } from '@/components/Section'
import MessageLabelKey from '@/classes/MessageLabelKey'
import { TextMixins } from '@/mixins'
import { mediaLink } from '@/constants/links'
import Link from '@/components/Media/Link.vue'

export default {
  mixins: [TextMixins],
  data() {
    return {
      mediaLink: mediaLink,
      MessageLabelKey: MessageLabelKey,
      references: [
        {
          img: 'https://www.ig-group.ch/wp-content/uploads/2023/07/logo_nouvelliste-min.png',
          text: 'Le bureau d’ingénieurs et de géomètres IG group développe une solution capable de déterminer la composition de l’eau présente dans les réseaux.',
          date: new Date(2021, 8, 3),
          url: 'https://www.ig-group.ch/wp-content/uploads/2015/03/20210903_Article_Nouvelliste1_compressed.pdf'
        },
        {
          img: 'https://www.ig-group.ch/wp-content/uploads/2023/07/logo_rhone_fm.png',
          text: "Prix Créateur BCVs 2021 - Créateur 5: Aquaspy - Gestion monitorée des réseaux d'eau potable",
          date: new Date(2021, 8, 3),
          url: 'https://www.rhonefm.ch/player/podcasts/prix-createur-bcvs-2021-createur-5-aquaspy-gestion-monitoree-des-reseaux-deau-potable'
        },
        {
          img: 'https://www.ig-group.ch/wp-content/uploads/2023/07/logo_prix_createurs_2021_FR-scaled.jpg',
          text: "Aquaspy finaliste du Prix Créateur BCVs 2021",
          date: new Date(2021, 8, 22),
          url: 'https://youtu.be/8SSZAVLNlMw'
        },
        {
          img: 'https://blog.theark.ch/css2019/img/logo-the-ark.png',
          text: 'Val de Bagnes, capitale de la gestion de l’eau !',
          date: new Date(2019, 10, 17),
          url: 'https://blog.theark.ch/fr/detail/val-de-bagnes-capitale-de-la-gestion-de-l-eau-9959',
          dark: true
        }
      ]
    }
  },
  components: { ViewContainer: ViewContainer, Section: Section, Link }
}
</script>

<style>

</style>