<template>
  <v-container class="section">
    <v-row>
      <v-col cols="12" v-if="!!title" class="mb-3">
        <v-row no-gutters justify="center">
          <span class="justify-center text-h3">{{title}}</span>
        </v-row>
      </v-col>
      <v-col cols="12">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style lang="scss">
@import "scssVariables";

.section {
  @include respond-mobile {
    padding-top: 40px;
  }
  @include respond-tablet {
    padding: 40px;
  }
  @include respond-desktop {
    padding: 60px !important;
  }
}

</style>