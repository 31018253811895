<template>
    <v-card height="100%" class="mr-md-4 d-flex flex-column">
      <v-card-text class="flex-grow-1">
    <v-row>
      <v-col cols="12">
        <v-row no-gutters justify="center">
          <v-img contain :max-width="imgWidth" :src="imgSrc"/>
        </v-row>
      </v-col>
      <v-col cols="12">
        <p class="text-primary text-justify">
          <slot/>
        </p>

      </v-col>
    </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="end">
                  <v-btn :href="link">{{$t(MessageLabelKey.SEE_MORE)}}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
import MessageLabelKey from '@/classes/MessageLabelKey'

export default {
  data() {
    return  {
      MessageLabelKey: MessageLabelKey
    }
  },
  props: ['imgSrc', 'link', 'imgWidth']
}
</script>

<style>

</style>