<template>
  <v-app-bar class="header" color="rgba(0, 0, 0, 0.7)" flat app>
    <a @click="clickGoTo(firstViewLink.to)"><v-img max-width="140px" max-height="100%" :src="logo"/></a>
    <template v-if="largeScreenMode">
      <v-spacer/>
        <v-btn dark text :small="mediumScreenMode" v-for="(link, index) in wordLinks" :key="index" @click="clickGoTo(link.to)">{{$t(link.label)}}</v-btn>
      <v-spacer/>
      <v-btn elevation="3" dark :color="mainColor" @click="clickGoTo(contactLink.to)">{{$t(MessageLabelKey.CONTACT_US)}}</v-btn>
      <LangSelector :dark="true" class="ml-3"/>
    </template>
    <template v-else>
      <v-spacer/>
      <v-btn @click="showDrawer" dark icon><v-icon color="white">{{mdiMenu}}</v-icon></v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import MessageLabelKey from '@/classes/MessageLabelKey'
import screenMixins from '@/mixins/ScreenMixins'
import { links, contactLink, firstViewLink } from '@/constants/links'
import LangSelector from '@/components/LangSelector'
import { mainColor } from 'scssJSVariables'
import { mdiMenu } from "@mdi/js";

export default {
  mixins: [screenMixins],
  props: ['showDrawer'],
  data () {
    return {
      mdiMenu,
      mainColor: mainColor,
      MessageLabelKey: MessageLabelKey,
      logo: require('@/assets/logo_sans_texte_simple.svg'),
      contactLink: contactLink,
      firstViewLink: firstViewLink
    }
  },
  computed: {
    wordLinks() {
      return links.filter(link => link.to !== contactLink.to && link.to !== contactLink.to)
    }
  },
  methods: {
    clickGoTo(id) {
      this.$vuetify.goTo(`#${id}`)
    }
  },
  components: { LangSelector: LangSelector }
}
</script>

<style lang="scss">
@import "scssVariables";

.header {
  .v-toolbar__content {
    // padding-left: 0;
    // padding-right: 0;
  }
  @include respond-mobile {
    padding: 0 calc(calc(100% - #{$main-horizontal-width-mobile}) / 2);
  }
  @include respond-tablet {
    padding: 0 calc(calc(100% - #{$main-horizontal-width-tablet}) / 2);
  }
  @include respond-desktop {
    padding: 0 calc(calc(100% - #{$main-horizontal-width-desktop}) / 2);
  }
}

</style>