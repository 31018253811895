<template>
  <v-row class="mb-8">
    <v-col v-if="imageBeforeText">
      <v-img contain :max-height="imageHeight" :src="imgSrc"/>
    </v-col>
    <v-col>
      <v-row>
        <v-col cols="12">
          <span class="text-h2 mr-4">{{stepNumber}}</span> <h2 class="text-h5">{{title}}</h2>
        </v-col>
        <v-col cols="12">
          <p class="text-justify"><slot/></p>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="!imageBeforeText">
      <v-img contain :max-height="imageHeight" :src="imgSrc"/>
    </v-col>  
  </v-row>
</template>

<script>
import screenMixins from '@/mixins/ScreenMixins'

export default {
  mixins: [screenMixins],
  props: ['stepNumber', 'imgSrc', 'title'],
  data() {
    return {
      imageHeight: "400px"
    }
  },
  computed: {
    imageBeforeText() {
      return this.stepNumber % 2 == 0 && this.largeScreenMode
    }
  }
}
</script>

<style>

</style>