<template>
  <ViewContainer :id="functioningLink.to">
    <v-row no-gutters>
      <Section :title="$t(MessageLabelKey.FUNCTIONING)">
        <Step :imgSrc="require('@/assets/reseau.svg')" stepNumber="1" :title="$t(MessageLabelKey.FUNCTIONING_STEP_1_TITLE)">
          {{$t(MessageLabelKey.FUNCTIONING_STEP_1_TEXT)}}
        </Step>
        <Step :imgSrc="require('@/assets/simulation.svg')" stepNumber="2" :title="$t(MessageLabelKey.FUNCTIONING_STEP_2_TITLE)">
          {{$t(MessageLabelKey.FUNCTIONING_STEP_2_TEXT)}}
        </Step>
        <Step :imgSrc="require('@/assets/plateformes.svg')" stepNumber="3" :title="$t(MessageLabelKey.FUNCTIONING_STEP_3_TITLE)">
          <MultilineText>{{$t(MessageLabelKey.FUNCTIONING_STEP_3_TEXT)}}</MultilineText>
        </Step>
      </Section>
    </v-row>
  </ViewContainer>
</template>

<script>
import ViewContainer from '@/components/ViewContainer'
import { Section } from '@/components/Section'
import Step from '@/components/Functioning/Step'
import MultilineText from '@/components/MultilineText'
import MessageLabelKey from '@/classes/MessageLabelKey'
import { TextMixins } from '@/mixins'
import { functioningLink } from '@/constants/links'

export default {
  mixins: [TextMixins],
  data() {
    return {
      functioningLink: functioningLink,
      MessageLabelKey: MessageLabelKey
    }
  },
  components: { ViewContainer: ViewContainer, Section: Section, Step: Step, MultilineText: MultilineText }
}
</script>

<style>

</style>