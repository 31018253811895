<template>
  <div>
    <template v-for="(lang, index) in langs">
      <template v-if="index > 0 && !(!smallScreenMode && index === 2)">|</template>
      <template v-if="index === 2 && !smallScreenMode"><br :key="`br-${index}`"/></template>
      <v-btn :dark="dark" :color="$root.$i18n.locale === lang.val ? '' : 'grey'" class="mx-1" x-small text :key="`lang-${index}`" @click="changeLang(lang.val)">
        {{lang.label}}
      </v-btn>
    </template>
  </div>
</template>

<script>
import screenMixins from '@/mixins/ScreenMixins'

export default {
  mixins: [screenMixins],
  props: ['dark'],
  data() {
    return {
      langs: [
        { label: "fr", val: 'fr' },
        { label: "de", val: 'de' },
        { label: "it", val: 'it' },
        { label: "en", val: 'en' },
      ]
    }
  },
  methods: {
    changeLang(val) {
      this.$root.$i18n.locale = val
    },

  }
}
</script>

<style>

</style>