export default class MessageLabelKey {

  static SEE_MORE = "SEE_MORE"

  static FIRST_VIEW_CATCH_PHRASE = "FIRST_VIEW_CATCH_PHRASE"
  static FIRST_VIEW_EXPLANATION_1 = "FIRST_VIEW_EXPLANATION_1"

  static FUNCTIONING = "FUNCTIONING"
  static SOLUTIONS = "SOLUTIONS"
  static WHO = "WHO"
  static CONTACT_US = "CONTACT_US"

  static SOLUTIONS_VIEW_TITLE = "SOLUTIONS_VIEW_TITLE"
  static AQUASPY_MANAGER_TITLE = "AQUASPY_MANAGER_TITLE"
  static AQUASPY_PUBLIC_TITLE = "AQUASPY_PUBLIC_TITLE"
  static AQUASPY_MANAGER_SUBTITLE = "AQUASPY_MANAGER_SUBTITLE"
  static SOLUTIONS_MANAGER_VISUALIZE_TITLE = "SOLUTIONS_MANAGER_VISUALIZE_TITLE"
  static SOLUTIONS_MANAGER_VISUALIZE_TEXT = "SOLUTIONS_MANAGER_VISUALIZE_TEXT"
  static SOLUTIONS_MANAGER_ALERT_TITLE = "SOLUTIONS_MANAGER_ALERT_TITLE"
  static SOLUTIONS_MANAGER_ALERT_TEXT = "SOLUTIONS_MANAGER_ALERT_TEXT"
  static SOLUTIONS_MANAGER_SAMPLE_TITLE = "SOLUTIONS_MANAGER_SAMPLE_TITLE"
  static SOLUTIONS_MANAGER_SAMPLE_TEXT = "SOLUTIONS_MANAGER_SAMPLE_TEXT"

  static AQUASPY_PUBLIC_SUBTITLE = "AQUASPY_PUBLIC_SUBTITLE"
  static SOLUTIONS_PUBLIC_CHECK_TITLE = "SOLUTIONS_PUBLIC_CHECK_TITLE"
  static SOLUTIONS_PUBLIC_CHECK_TEXT = "SOLUTIONS_PUBLIC_CHECK_TEXT"
  static SOLUTIONS_PUBLIC_INFORMED_TITLE = "SOLUTIONS_PUBLIC_INFORMED_TITLE"
  static SOLUTIONS_PUBLIC_INFORMED_TEXT = "SOLUTIONS_PUBLIC_INFORMED_TEXT"

  static FUNCTIONING_STEP_1_TITLE = "FUNCTIONING_STEP_1_TITLE"
  static FUNCTIONING_STEP_1_TEXT = "FUNCTIONING_STEP_1_TEXT"
  static FUNCTIONING_STEP_2_TITLE = "FUNCTIONING_STEP_2_TITLE"
  static FUNCTIONING_STEP_2_TEXT = "FUNCTIONING_STEP_2_TEXT"
  static FUNCTIONING_STEP_3_TITLE = "FUNCTIONING_STEP_3_TITLE"
  static FUNCTIONING_STEP_3_TEXT = "FUNCTIONING_STEP_3_TEXT"

  static STAFF = "STAFF"
  static STAFF_HISTORY = "STAFF_HISTORY"
  static STAFF_PARTNERS = "STAFF_PARTNERS"
  static STAFF_IG = "STAFF_IG"
  static STAFF_ALTIS = "STAFF_ALTIS"
  static STAFF_BLUEARK = "STAFF_BLUEARK"
  static STAFF_HISTORY_TEXT = "STAFF_HISTORY_TEXT"
  static MEDIA_SECTION_TITLE = "MEDIA_SECTION_TITLE"

  static NEWSLETTER_DONT_MISS = "NEWSLETTER_DONT_MISS"
  static WRITE_EMAIL = "WRITE_EMAIL"
  static EMAIL_REQUIRED = "EMAIL_REQUIRED"
  static INVALID_EMAIL = "INVALID_EMAIL"
}
