<template>
  <v-row class="py-4">
    <v-col>
      <v-row justify="center" no-gutters v-if="!!title" class="mb-2"><span class="text-h4">{{title}}</span></v-row>
      <v-row justify="center" no-gutters v-if="!!subtitle" class="mb-8"><span class="subtitle-1 text--secondary">{{subtitle}}</span></v-row>
      <v-row no-gutters class="py-3"><slot></slot></v-row>
    </v-col>
  </v-row>
</template>

<script>
import screenMixins from '@/mixins/ScreenMixins'

export default {
  mixins: [screenMixins],
  props: ["title", "subtitle"]
}
</script>

<style lang="scss">

</style>