<template>
  <v-navigation-drawer
    v-model="drawer"
    class="d-flex d-md-none navbar"
    absolute
    right
    top
    temporary
  >
    <v-list
    >
      <v-list-item v-for="(link, index) in links" :key="index" @click="clickGoTo(link.to)">
        <v-list-item-content>
          <v-list-item-title>{{$t(link.label)}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer/>
    <LangSelector class="mt-8"/>
  </v-navigation-drawer>
</template>

<script>
import { links } from '@/constants/links'
import LangSelector from '@/components/LangSelector'

export default {
  data () {
    return {
      links: links,
      drawer: false,
    }
  },
  methods: {
    clickGoTo(id) {
      this.setDrawerVisible(false)
      this.$vuetify.goTo(`#${id}`)
    }
  },
  watch: {
    propDrawer (value) {
      this.drawer = value
    },
    drawer (value) {
      this.setDrawerVisible(value)
    }
  },
  props: ['propDrawer', 'setDrawerVisible'],
  components: { LangSelector: LangSelector }
}
</script>

<style lang="scss">
.navbar.v-navigation-drawer--absolute {
  position: fixed;
  z-index: 10
}
</style>