import MessageLabelKey from '@/classes/MessageLabelKey'

export const contactLink = { label: MessageLabelKey.CONTACT_US, to: "Contact" }
export const functioningLink = { label: MessageLabelKey.FUNCTIONING, to: "Functioning" }
export const solutionsLink = { label: MessageLabelKey.SOLUTIONS, to: "Solutions" }
export const whoLink = { label: MessageLabelKey.WHO, to: "Who" }
export const firstViewLink = { label: "firstView", to: "FirstView" }
export const mediaLink = { label: MessageLabelKey.MEDIA_SECTION_TITLE, to: "media" }

export const links = [
  solutionsLink,
  functioningLink,
  whoLink,
  contactLink,
  mediaLink
]