<template>
  <v-card :href="url" link height="100%" v-bind:class="{ dark: dark }" class="d-flex flex-column justify-space-between">
    <div>
      <div class="img-wrapper px-4">
        <v-img
          contain
          height="200"
          :src="img"
          :dark="dark"
        />
      </div>
      <v-card-text class="font-weight-light px-4 text--primary">
        <MultilineText>
          {{text}}
        </MultilineText>
      </v-card-text>
    </div>
    <v-card-actions class="text-caption text--secondary px-4">
      {{date.toLocaleString($i18n.locale, { dateStyle: 'short' })}}
    </v-card-actions>
  </v-card>
</template>

<script>
import MultilineText from '@/components/MultilineText.vue'

export default {
  props: ['img', 'date', 'text', 'url', 'dark'],
  components: { MultilineText }
}
</script>

<style lang="scss">
.dark {
  .img-wrapper {
    background: #B0BEC5;
  }
}

</style>