<template>
  <div class="home">
    <FirstView/>
    <Solutions/>
    <Functioning/>
    <Staff/>
    <Media/>
    <Contact/>
    <v-snackbar v-if="false" height="250" light :right="!smallScreenMode" :centered="!smallScreenMode" v-model="snackbar" :timeout="-1">
        <div>
        <v-row class="mb-4" no-gutters>
          <v-img contain max-width="200" src='@/assets/bcvs.png'/>
        </v-row>
        <v-row no-gutters>
          <p class="text-subtitle-2">
          Aquaspy est finaliste du prix BCV
          </p>
        </v-row>
        <v-row no-gutters>
          <v-btn href="https://www.prixcreateurbcvs.ch/fr/page_simple/vote-28" class="mt-3">votez pour nous</v-btn>
        </v-row>
        </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>


<script>
import FirstView from '@/components/FirstView'
import Solutions from './Solutions'
import Functioning from './Functioning'
import Staff from './Staff'
import Contact from './Contact'
import Media from './Media'
import { mainColor } from 'scssJSVariables'
import { mdiClose } from '@mdi/js';
import { ScreenMixins } from '@/mixins'

export default {
  name: 'Home',
  mixins: [ScreenMixins],
  data () {
    return {
      snackbar: false,
      mainColor: mainColor,
      mdiClose: mdiClose
    }
  },
  mounted() {
    setTimeout(() => {
      this.snackbar = true
    }, 5000)
  },
  components: {
    FirstView,
    Solutions,
    Functioning,
    Staff,
    Contact,
    Media
  }
}
</script>
