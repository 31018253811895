const largeScreenMode = (val) => {
  switch (val) {
    case 'xs':
    case 'sm': return false
    case 'md': 
    case 'lg':
    case 'xl': return true
  }
}

export default {
  computed: {
    smallScreenMode() {
      return !largeScreenMode(this.$vuetify.breakpoint.name) && !this.mediumScreenMode
    },
    mediumScreenMode() {
      return this.$vuetify.breakpoint.name === "md"
    },
    largeScreenMode() {
      return largeScreenMode(this.$vuetify.breakpoint.name) && !this.mediumScreenMode
    }
  }
}