<template>
  <span style="white-space: pre-wrap; text-align: justify;">
    <slot/>
  </span>
</template>

<script>
export default {

}
</script>

<style>

</style>